import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);
const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/login",
  },
  // 登录页
  {
    path: "/login",
    component: () => import("../views/login.vue"),
  },
  {
    path: "/topicManage",
    meta: { titel: "题库管理", icon: "loop" },
    component: () => import("../views/layout/layout.vue"),
    children: [
      {
        path: "/topicManage",
        meta: { titel: "题库管理", icon: "loop" },
        component: () => import("../views/topicManage/index.vue"),
      },
    ],
  },
  {
    path: "/activeManage",
    meta: { titel: "活动管理", icon: "loop" },
    component: () => import("../views/layout/layout.vue"),
    children: [
      {
        path: "/activeManage",
        meta: { titel: "活动管理", icon: "loop" },
        component: () => import("../views/activeManage/index.vue"),
      },
    ],
  },
  {
    path: "/prizeManage",
    meta: { titel: "奖品管理", icon: "loop" },
    component: () => import("../views/layout/layout.vue"),
    children: [
      {
        path: "/prizeManage",
        meta: { titel: "奖品管理", icon: "loop" },
        component: () => import("../views/prizeManage/index.vue"),
      },
    ],
  },
  {
    path: "/winnerManage",
    meta: { titel: "中奖管理", icon: "loop" },
    component: () => import("../views/layout/layout.vue"),
    children: [
      {
        path: "/winnerManage",
        meta: { titel: "中奖管理", icon: "loop" },
        component: () => import("../views/winnerManage/index.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
