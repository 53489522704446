<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
document.addEventListener("gesturestart", function (event) {
  event.preventDefault();
});
</script>

<style>
#app {
  width: 100%;
  height: 100%;
}
</style>
