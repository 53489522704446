import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/global.css";
import "./assets/iconfont/font_4234502_hb8p3srj408/iconfont.css";
import "./assets/iconfont/font_4234502_vvyiejz95z/iconfont.css";
import VueLuckyCanvas from "@lucky-canvas/vue";
import plugins from "./components/plugins";
//图片放大组件
Vue.use(VueLuckyCanvas);
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(plugins);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
