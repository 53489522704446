import { MessageBoxData } from "element-ui/types/message-box";
import { VNode } from "vue";
import modal from "./modal";

export default {
  install(Vue: {
    prototype: {
      $modal: {
        msg(content: string): void;
        msgError(
          content: // 模态框对象
          string
        ): void;
        msgSuccess(content: string): void;
        msgWarning(content: string): void;
        alert(content: string): void;
        alertError(content: string): void;
        alertSuccess(content: string): void;
        alertWarning(content: string): void;
        notify(content: string | VNode): void;
        notifyError(content: string | VNode): void;
        notifySuccess(content: string | VNode): void;
        notifyWarning(content: string | VNode): void;
        confirm(content: string): Promise<MessageBoxData>;
        prompt(content: string): Promise<MessageBoxData>;
        loading(content: any): void;
        closeLoading(): void;
      };
    };
  }) {
    // 模态框对象
    Vue.prototype.$modal = modal;
  },
};
